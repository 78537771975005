import React from 'react';
import { Modal, Button, Box, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import { saveAs } from 'file-saver';
import QrCodeIcon from '@mui/icons-material/QrCode';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

interface QRCodeModalProps {
    open: boolean;
    onClose: () => void;
    qrValue: string;
}

export const QRCodeModal: React.FC<QRCodeModalProps> = ({ open, onClose, qrValue }) => {
    const handleDownload = () => {
        const canvas = document.getElementById('qr-code') as HTMLCanvasElement;
        canvas.toBlob((blob) => {
            if (blob) {
                saveAs(blob, 'veewer-ar-qrcode.png');
            }
        });
    };

    const handleGoToAR = () => {
        window.open(qrValue, '_blank');
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="qr-code-modal"
            aria-describedby="qr-code-display"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{
                backgroundColor: 'white',
                padding: '32px',
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '24px',
                maxWidth: '400px',
                width: '90%',
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            }}>
                <Typography variant="h5" component="h2" sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: '8px',
                    color: '#1976d2',
                    fontWeight: 600
                }}>
                    <QrCodeIcon /> AR View QR Code
                </Typography>

                <Box sx={{
                    padding: '16px',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <QRCode
                        id="qr-code"
                        value={qrValue}
                        size={256}
                        level="H"
                        includeMargin={true}
                    />
                </Box>

                <Box sx={{
                    display: 'flex',
                    gap: '12px',
                    width: '100%',
                    justifyContent: 'center'
                }}>
                    <Button
                        variant="contained"
                        onClick={handleDownload}
                        startIcon={<FileDownloadIcon />}
                        sx={{
                            backgroundColor: '#2e7d32',
                            '&:hover': {
                                backgroundColor: '#1b5e20',
                            }
                        }}
                    >
                        Download
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleGoToAR}
                        startIcon={<ViewInArIcon />}
                        sx={{
                            backgroundColor: '#1976d2',
                            '&:hover': {
                                backgroundColor: '#1565c0',
                            }
                        }}
                    >
                        Go to AR
                    </Button>
                </Box>

                <Typography variant="body2" color="text.secondary" align="center">
                    Scan the QR code for AR view or use the Go to AR button directly
                </Typography>
            </Box>
        </Modal>
    );
}; 