import '../styles/login/loginSignUp.css';
import { useEffect, useState } from 'react';
import SignUp from '../components/SignUp/SignUp';
import Login from '../components/User/Login';
import { useAppDispatch, useAppSelector } from '../store';
import { fetchGoogleAuth } from '../store/features/auth/google.get.token.slice';
import { fetchMicrosoftAuth } from '../store/features/auth/microsoft.get.token.slice';
import { resetError } from '../store/features/auth/auth.post.slice';
import Footer from '../components/Footer/Footer';
import ELogo from "../assets/Veewer_black_stripes_E.png"
import logo from '../assets/Veewer_black_stripes_E.png';
import LoadingScreen from '../utils/LoadingScreen';
import { useSearchParams } from 'react-router-dom';


export default function LoginSignUp() {
	const [activeLoginAction, setActiveLoginAction] = useState(true);
	const [activeSignUpAction, setActiveSignUpAction] = useState(false);
	const [activeAction, setActiveAction] = useState('login');
	const getGoogleTokenSelector = useAppSelector((x) => x.googleAuthSlice)
	const getMicrosoftTokenSelector=useAppSelector((x)=>x.microsoftAuthSlice)
	const postRegisterSelector = useAppSelector((x) => x.fetchUserRegisterSlice);
	const loginSelector = useAppSelector((x) => x.postAuth);
	const dispatch = useAppDispatch()
	//const bucket=sessionStorage.getItem("b") as string
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const tabParam = searchParams.get('tab');
		if (tabParam === 'signup') {
			setActiveAction('signup');
			setActiveLoginAction(false);
			setActiveSignUpAction(true);
		}
	}, [searchParams]);

	function transitionToLogin() {
		setActiveLoginAction(true);
		setActiveSignUpAction(false);
		setActiveAction('login');
	}

	function transitionToSignUp() {
		if(loginSelector.error!==null){
			dispatch(resetError())
		}
		setActiveSignUpAction(true);
		setActiveLoginAction(false);
		setActiveAction('signup');
	}

	useEffect(() => {
		if (window.location.href.indexOf('?code') !== -1) {
			if (window.location.href.indexOf('google') !== -1) {
				const code = window.location.href
					.split('/login?')[1]
					.split('=')[1]
					.split('&')[0];

				dispatch(fetchGoogleAuth({
					code
				})).then(()=>{				
					if(getGoogleTokenSelector!=null && getGoogleTokenSelector.data!==null){
						sessionStorage.setItem("login",JSON.stringify({
							isAuthenticated: getGoogleTokenSelector.data.isAuthenticated,
							expiresAt: getGoogleTokenSelector.data.expiresAt,
						    isAdmin:getGoogleTokenSelector.data.isAdmin,
							isOwner:getGoogleTokenSelector.data.isOwner,
							isUser:getGoogleTokenSelector.data.isUser,
							uid: getGoogleTokenSelector.data.uid,
							microsoft_auth:false,
							google_auth:getGoogleTokenSelector.data.google_auth
						}))
					}			
					sessionStorage.setItem("google-auth",JSON.stringify({
						isSuccess:true
					}))
					transitionToLogin()
				})
			}
			else {
				const code = window.location.href
					.split('/login?')[1]
					.split('=')[1]
					.split('&')[0];

				dispatch(fetchMicrosoftAuth({
					code
				})).then(()=>{
					if(getMicrosoftTokenSelector!=null && getMicrosoftTokenSelector.data!==null){
						sessionStorage.setItem("login",JSON.stringify({
							isAuthenticated: getMicrosoftTokenSelector.data.isAuthenticated,
							expiresAt: getMicrosoftTokenSelector.data.expiresAt,
							isAdmin: getMicrosoftTokenSelector.data.isAdmin,
							isOwner: getMicrosoftTokenSelector.data.isOwner,
                            isUser: getMicrosoftTokenSelector.data.isUser,
							uid: getMicrosoftTokenSelector.data.uid,
							microsoft_auth:getMicrosoftTokenSelector.data.microsoft_auth,
							google_auth:false
						}))
					}			
					sessionStorage.setItem("microsoft-auth",JSON.stringify({
						isSuccess:true
					}))
					transitionToLogin()
				})
			}
		}
	}, [])

	return (
		<div className="w-full min-h-screen flex flex-col">
			<div className="min-h-[90vh] flex flex-col items-center justify-center w-full px-4 sm:px-6 lg:px-8">
				{postRegisterSelector.loading || loginSelector.loading ? (
					<LoadingScreen />
				) : (
					<>
						<div className="flex flex-col items-center mb-6 sm:mb-8 w-full">
							<div className="flex items-center justify-center gap-6 mb-2">
								<img src={logo} alt="Veewer Logo" className="h-12 w-auto hidden md:block" />
								<div className="flex flex-col md:text-left xs:text-center">
									<h1 className="text-2xl sm:text-3xl font-bold text-gray-900">Welcome to VEEWER</h1>

								</div>
							</div>
						</div>

						<div className="w-full max-w-md">
							<div className="flex border-b border-gray-200 mb-4 sm:mb-6">
								<button
									onClick={transitionToLogin}
									className={`flex-1 text-center py-2 sm:py-3 cursor-pointer transition-all duration-300 relative text-sm sm:text-base ${
										activeAction === "login"
											? 'text-[#16254c] font-medium'
											: 'text-gray-500 hover:text-gray-700'
									}`}
								>
									<span className="relative z-10">Login</span>
									{activeAction === "login" && (
										<div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-[#16254c] to-[#1e3a8a] transition-all duration-300" />
									)}
								</button>
								<button
									onClick={transitionToSignUp}
									 className={`flex-1 text-center py-2 sm:py-3 cursor-pointer transition-all duration-300 relative text-sm sm:text-base ${
										activeAction === "signup"
											? 'text-[#16254c] font-medium'
											: 'text-gray-500 hover:text-gray-700'
									}`}
								>
									<span className="relative z-10">Sign Up</span>
									{activeAction === "signup" && (
										<div className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-[#16254c] to-[#1e3a8a] transition-all duration-300" />
									)}
								</button>
							</div>

							<div className="min-h-[400px] flex flex-col px-4 sm:px-0">
								{activeAction === "login" ? (
									<Login />
								) : (
									<SignUp setActiveAction={setActiveAction} />
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}