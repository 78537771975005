import { BaseModel } from "../../models/base/base.model";
import { BaseService } from "./base.service";
import axios from "axios";

export class BaseDeleteService<TResponse extends BaseModel> extends BaseService{
    async DeleteAsync(){     
        const res=await this.request.delete<TResponse>("")
        return res.data
    }

    async DeleteByStringParamByAsync(param:string){
        const res= await this.request.delete<TResponse>(`/${param}`)
        return res.data
    }

    async DeleteByStringManyParamByAsync(params:string[]){
        try {
            let text:string=""
            params.map((param)=>{
                text+=`/${param}`
            })

            const res = await this.request.delete<TResponse>(text)
            return res.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Server responded with a status code outside of 2xx range
                    throw new Error(error.response.data?.message || 'Failed to delete the model');
                } else if (error.request) {
                    // Request was made but no response received
                    throw new Error('No response received from server');
                } else {
                    // Something happened in setting up the request
                    throw new Error('Error setting up the request');
                }
            }
            // For non-Axios errors
            throw new Error('An unexpected error occurred');
        }
    }

    async DeleteByNumnerParamByAsync(param:number){
        return await this.request.delete<any,TResponse>(`/${param}`)
    }

    async DeleteByNumberManyParamByAsync(params:number[]){
        let text:string=""
        params.map((param)=>{
            text+=`/${param}`
        })

        return await this.request.delete<any,TResponse>(text)
    }
}