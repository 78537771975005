import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseState } from "../../../commons/base.state";
import { DeleteUploadedObjectResponseModel } from "../../../models/object/uploadedObject/delete.uploadedObject.response.model";
import { DeleteUploadedObjectService } from "../../../services/uploadedObject/delete.uploadedObject.service";
import { DeleteUploadedObjectRequestModel } from "../../../models/object/delete.uploadedObject.request.model";
import axios from 'axios';
import { baseUrl } from '../../../config/axios.config';

interface DeleteUploadedObjectState extends BaseState<DeleteUploadedObjectResponseModel>{

}

let initialState:DeleteUploadedObjectState={
    data:null,
    error:null,
    loading:false
}

export const deleteUploadedObject = createAsyncThunk(
  'deleteObject',
  async (params: DeleteUploadedObjectRequestModel) => {
    const response = await axios.delete(`${baseUrl}objects/users/${params.userId}/objects/${params.objectId}`);
    return response.data;
  }

);

export const deleteUploadedObjectSlice=createSlice({
    name:"createUploadedObjectSlice",
    initialState,
    reducers:{
        resetDeleteState:()=>{
            initialState.data=null
            initialState.error=null
            initialState.loading=false
        }
    },
    extraReducers(builder){
        builder
        .addCase(deleteUploadedObject.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(deleteUploadedObject.fulfilled,(state,action)=>{
            state.loading=false
            state.data=action.payload
        })
        .addCase(deleteUploadedObject.rejected,(state,action:PayloadAction<any>)=>{
            state.loading=false
            state.error=action.payload
        })
        
    }
})

export const {resetDeleteState}=deleteUploadedObjectSlice.actions
export default deleteUploadedObjectSlice.reducer











