import { baseUrl } from "../../config/axios.config";

interface QrSourceCodeResponse {
    qrSourceCode: boolean;
}

export class GetQrSourceCodeService {
    public async GetByStringAsync(modelId: string): Promise<QrSourceCodeResponse> {
        const response = await fetch(`${baseUrl}objects/getQrSourceCode/${modelId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to get QR source code');
        }

        return await response.json();
    }

    public generateQrCodeUrl(userId: string, modelId: string): string {
        return `ar-cloudflare.vercel.app?userId=${userId}&modelId=${modelId}`;
    }
} 