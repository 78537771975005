import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector, RootState } from '../../store';
import { fetchUserInfo } from '../../store/features/user/get.user.slice';
import { LoginResponse } from '../../models/auth/login.model';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PaymentIcon from '@mui/icons-material/Payment';
import LoadingScreen from '../../utils/LoadingScreen';
import Footer from '../../components/Footer/Footer';

export default function UserProfile() {
  const dispatch = useAppDispatch();
  const userInfoSlice = useAppSelector((state: RootState) => state.userInfoSlice);
  const userInfo = userInfoSlice.data;
  const login = sessionStorage.getItem('login') as string;
  const jsonLogin = JSON.parse(login) as LoginResponse;

  useEffect(() => {
    dispatch(fetchUserInfo(jsonLogin.uid));
  }, [dispatch, jsonLogin.uid]);

  if (!userInfo || userInfoSlice.error) {
    return <LoadingScreen />;
  }

  const creditUsagePercentage = (userInfo.usedCredit / (userInfo.usedCredit + userInfo.availableCredit)) * 100;

  return (
    <div className="h-[calc(100vh-80px)] overflow-y-auto bg-white">
      <div className="max-w-3xl mx-auto px-4 py-6">
        {/* Profile Header */}
        <div className="flex items-center gap-4 mb-8">
          <div className="w-16 h-16 bg-blue-500 rounded-lg flex items-center justify-center">
            <AccountCircleIcon className="w-10 h-10 text-white" />
          </div>
          <div>
            <h1 className="text-xl font-semibold text-gray-900">{userInfo.userName}</h1>
            <p className="text-sm text-gray-500">{userInfo.email}</p>
            <span className="inline-block mt-2 px-3 py-1 bg-blue-50 text-blue-600 text-xs font-medium rounded-full">
              {/* {userInfo.subscriptionType} */}
            </span>
          </div>
        </div>

        {/* Info Grid */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <InfoItem icon={<EmailIcon />} label="Email" value={userInfo.email} />
          <InfoItem icon={<PhoneIcon />} label="Phone" value={userInfo.phoneNumber || "Not provided"} />
          <InfoItem icon={<BusinessIcon />} label="Company" value={userInfo.companyName} />
          {/* <InfoItem icon={<PaymentIcon />} label="Plan" value={userInfo.subscriptionType} /> */}
        </div>

        {/* Credit Usage */}
        <div className="border rounded-lg p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-sm font-medium text-gray-900">Credit Usage</h2>
            <span className="text-sm text-blue-600">{creditUsagePercentage.toFixed(1)}%</span>
          </div>

          {/* Progress Bar */}
          <div className="h-2 bg-gray-100 rounded-full mb-4">
            <div 
              className="h-full bg-blue-500 rounded-full transition-all duration-500"
              style={{ width: `${creditUsagePercentage}%` }}
            />
          </div>

          {/* Credit Details */}
          <div className="flex justify-between text-sm">
            <div>
              <span className="text-gray-500">Used: </span>
              <span className="font-medium text-gray-900">{userInfo.usedCredit}</span>
            </div>
            <div>
              <span className="text-gray-500">Available: </span>
              <span className="font-medium text-gray-900">{userInfo.availableCredit}</span>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  
  );
}

function InfoItem({ icon, label, value }: { icon: React.ReactNode; label: string; value: string }) {
  return (
    <div className="flex items-center gap-3 p-3 border rounded-lg bg-gray-50">
      <div className="text-blue-500">
        {icon}
      </div>
      <div className="min-w-0">
        <p className="text-xs text-gray-500">{label}</p>
        <p className="text-sm font-medium text-gray-900 truncate">{value}</p>
      </div>
    </div>
  );
}