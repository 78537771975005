import React, { useState } from 'react';

interface CardItemProps {
  imageName: string | JSX.Element;
  title?: string;
  text?: string;
  buttonNames: (string | JSX.Element)[];
  buttonEvents: React.MouseEventHandler<HTMLButtonElement>[];
  onDelete: () => void;
  id?: string;
  onClickToCard: () => void;
  setDeleteText?: (value: React.SetStateAction<string>) => void;
  tickIcon?: JSX.Element;
  loading?: any;
  isActive: boolean;
  isCloud?: boolean;
}

const CardItem: React.FC<CardItemProps> = ({
  title,
  text,
  setDeleteText,
  imageName,
  buttonNames,
  buttonEvents,
  onDelete,
  onClickToCard,
  tickIcon,
  isActive,
  isCloud = false,
}) => {
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [showViewerNotification, setShowViewerNotification] = useState(false);
  const [deleteHovered, setDeleteHovered] = useState(false);
  const underscoreIndex = title?.indexOf('_');
  const dotIndex = title?.lastIndexOf('.');
  let name = underscoreIndex !== -1 ? title?.slice(0, underscoreIndex) : title?.slice(0, dotIndex);

  return (
    <div 
      title={name || title}
      className={`relative xs:max-w-[165px] max-w-[180px] h-[240px] overflow-hidden rounded-2xl border transition-all duration-300 ease-in-out bg-white
        ${!isActive ? 'opacity-50 pointer-events-none' : 'hover:shadow-xl hover:scale-102'}
      `}
      onMouseEnter={() => isActive && setShowDeleteBtn(true)}
      onMouseLeave={() => {
        isActive && setShowDeleteBtn(false);
        setDeleteHovered(false);
      }}
    >
      {/* Delete Button */}
      {showDeleteBtn && isActive && !isCloud && (
        <button
          title="Delete Model"
          className={`absolute top-2 right-2 z-10 flex items-center overflow-hidden
            ${deleteHovered ? 'w-24 bg-red-100' : 'w-8 bg-white bg-opacity-90'}
            rounded-full p-1.5 transition-all duration-300 shadow-lg hover:shadow-xl
            group
          `}
          onClick={() => {
            if (setDeleteText !== undefined && title !== undefined) {
              setDeleteText(title);
            }
            onDelete();
          }}
          onMouseEnter={() => setDeleteHovered(true)}
          onMouseLeave={() => setDeleteHovered(false)}
        >
          <div className="flex items-center space-x-2">
            <div className={`flex-shrink-0 rounded-full p-1 ${deleteHovered ? 'bg-red-200' : ''} transition-colors duration-300`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-4 w-4 transition-colors duration-300 ${deleteHovered ? 'text-red-600' : 'text-red-500'}`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <span className={`text-sm font-medium text-red-600 whitespace-nowrap transition-opacity duration-300
              ${deleteHovered ? 'opacity-100' : 'opacity-0'}
            `}>
              Delete
            </span>
          </div>
        </button>
      )}

      {/* Image Section */}
      <div 
        className="h-32 overflow-hidden relative cursor-pointer group"
        onMouseEnter={() => setShowViewerNotification(true)}
        onMouseLeave={() => setShowViewerNotification(false)}
        onClick={() => isActive && onClickToCard()}
      >
        {typeof imageName === 'string' ? (
          <>
            <img
              src={imageName}
              alt={title || 'Card image'}
              className="w-full h-full object-contain object-center transition-transform duration-300 group-hover:scale-110"
            />
            {showViewerNotification && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <span className="text-white text-xs font-medium px-3 py-1.5 bg-black bg-opacity-75 rounded-full transform scale-95 group-hover:scale-100 transition-transform duration-300">
                  {isCloud ? "Click to transfer" : "Click to view"}
                </span>
              </div>
            )}
          </>
        ) : (
          <div className="w-full h-full bg-gray-200 animate-pulse rounded-lg"></div>
        )}
      </div>

      {/* Content Section */}
      <div className="p-3">
        {title && (
          <h3 className="text-sm font-semibold mb-1 truncate text-gray-800">{name}</h3>
        )}
        {text && (
          <p className="text-xs text-gray-600 mb-2 overflow-hidden line-clamp-2">{text}</p>
        )}
        <div className="mt-auto flex flex-wrap gap-1.5 items-center">
          {buttonNames.map((buttonName, i) => (
            <button
              key={i}
              onClick={isActive ? buttonEvents[i] : undefined}
              className={`px-2.5 py-1 text-xs font-medium rounded-full transition-all duration-200 
                ${i === 0 
                  ? 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:from-blue-600 hover:to-indigo-700 hover:shadow-md transform hover:-translate-y-0.5' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:shadow-sm transform hover:-translate-y-0.5'}
              `}
            >
              {buttonName}
            </button>
          ))}
          {tickIcon && (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-green-500 ml-auto" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}

export default CardItem;

