'use client'

import React, { useState, useEffect } from "react"
import Footer from "../components/Footer/Footer"

// Video Card Component
function VideoCard({ 
  video, 
  onPlay, 
  isActive, 
  onFlip 
}: { 
  video: {
    url: string
    title: string
    description: string
  }
  onPlay: (video: { url: string; title: string }) => void 
  isActive: boolean
  onFlip: () => void
}) {
  // Masaüstü için hover ile flip
  const handleMouseEnter = () => {
    if (window.innerWidth > 1024) { // lg breakpoint
      onFlip();
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 1024) {
      onFlip();
    }
  };

  return (
    <div 
      className="flip-card h-72 w-full cursor-pointer perspective-1000"
      onClick={() => onFlip()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`flip-card-inner relative w-full h-full transition-transform duration-700 transform-style-preserve-3d ${isActive ? 'flipped' : ''}`}>
        {/* Front Side */}
        <div className="flip-card-front absolute w-full h-full bg-white/90 backdrop-blur-lg rounded-2xl shadow-[0_8px_32px_rgba(22,37,76,0.1)] p-8 flex flex-col items-center justify-center border border-white/40 group hover:shadow-[0_8px_32px_rgba(22,37,76,0.15)] transition-all duration-300">
          <div className="relative mb-6 transform group-hover:scale-110 transition-transform duration-300">
            <div className="absolute -inset-2 bg-gradient-to-r from-[#16254c]/20 to-blue-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
            <div className="relative bg-gradient-to-br from-[#16254c] to-blue-600 p-4 rounded-2xl text-white">
              <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </div>
          <h3 className="text-2xl font-semibold bg-gradient-to-br from-[#16254c] to-blue-600 bg-clip-text text-transparent text-center mb-3">{video.title}</h3>
          <p className="text-sm text-gray-500 mt-2 lg:hidden">Tap to see details</p>
        </div>

        {/* Back Side */}
        <div className="flip-card-back absolute w-full h-full bg-gradient-to-br from-[#16254c] to-blue-600 rounded-2xl shadow-[0_8px_32px_rgba(22,37,76,0.15)] p-8 flex flex-col items-center justify-center border border-white/10">
          <p className="text-white/90 text-center mb-8 text-lg leading-relaxed">{video.description}</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onPlay(video);
            }}
            className="group relative px-8 py-3 bg-white/10 backdrop-blur-sm rounded-full hover:bg-white/20 transition-all duration-300 border border-white/30"
          >
            <span className="relative z-10 text-white font-medium flex items-center gap-2">
              Watch Now
              <svg className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

// Video Modal Component
function VideoModal({ video, onClose }: { 
  video: { url: string; title: string } | null
  onClose: () => void 
}) {
  // ESC tuşu ile kapatma
  useEffect(() => {
    if (!video) return;
    
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose, video]);

  if (!video) return null;

  const videoId = video.url.split('v=')[1] || video.url.split('/').pop();

  // Dışarı tıklama ile kapatma
  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      {/* Background Overlay */}
      <div 
        className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm transition-opacity pointer-events-auto"
        onClick={handleBackdropClick}
      ></div>

      {/* Modal Container */}
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="relative w-full max-w-5xl overflow-hidden rounded-2xl bg-white shadow-2xl transform transition-all">
          {/* Modal Header */}
          <div className="relative bg-gradient-to-r from-[#16254c] to-blue-600 p-6">
            <div className="flex items-center justify-between">
              <h3 className="text-xl font-semibold text-white pr-8">{video.title}</h3>
              <button
                onClick={onClose}
                className="absolute right-6 top-6 rounded-full p-2 text-white/80 hover:text-white hover:bg-white/10 transition-colors"
                aria-label="Close modal"
              >
                <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>

          {/* Video Container with 16:9 Aspect Ratio */}
          <div className="relative bg-black">
            <div className="relative pt-[56.25%]">
              <iframe
                className="absolute inset-0 h-full w-full"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
                title={video.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          {/* Modal Footer */}
          <div className="bg-gray-50 px-6 py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <kbd className="px-2 py-1 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">ESC</kbd>
                <span className="text-sm text-gray-500">to close</span>
              </div>
              <a
                href={video.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 text-sm font-medium text-blue-600 hover:text-blue-700 transition-colors"
              >
                <span>Watch on YouTube</span>
                <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Main Component
export default function VeewerManual() {
  const [activeVideo, setActiveVideo] = useState<{ url: string; title: string } | null>(null);
  const [activeCardIndex, setActiveCardIndex] = useState<number | null>(null);

  const handleCardFlip = (index: number) => {
    setActiveCardIndex(activeCardIndex === index ? null : index);
  };

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      @media (min-width: 1024px) {
        .flip-card:hover .flip-card-inner {
          transform: rotateY(180deg);
        }
      }
      
      .flip-card-inner.flipped {
        transform: rotateY(180deg);
      }
      
      .flip-card-inner {
        transform-style: preserve-3d;
      }
      
      .flip-card-front,
      .flip-card-back {
        backface-visibility: hidden;
      }
      
      .flip-card-back {
        transform: rotateY(180deg);
      }
    `;
    
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const videos = [
    {
      url: "https://youtu.be/3m_SoQGBKkk",
      title: "How do I use the Embed Code?",
      description: "Learn how to easily integrate VEEWER's 3D models into your website using our embed code system."
    },
    {
      url: "https://youtu.be/5E8O0erhZWs",
      title: "How to create a configurator?",
      description: "Step-by-step guide to creating interactive 3D product configurators with VEEWER's powerful tools."
    },
    {
      url: "https://youtu.be/1xVv8dBv2FY",
      title: "Comprehensive VEEWER Tutorial",
      description: "Complete overview of VEEWER's features and capabilities to help you make the most of our platform."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white relative overflow-hidden">
      {/* Background Decorative Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#00000008_1px,transparent_1px),linear-gradient(to_bottom,#00000008_1px,transparent_1px)] bg-[size:4rem_4rem]"></div>
      </div>
      <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-[#16254c]/10 to-transparent rounded-full blur-3xl"></div>
      <div className="absolute bottom-0 left-0 w-1/3 h-1/3 bg-gradient-to-tr from-blue-600/10 to-transparent rounded-full blur-3xl"></div>

      {/* Hero Section */}
      <div className="relative pt-20 pb-32">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto mb-20">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-[#16254c] to-blue-600 bg-clip-text text-transparent">
              VEEWER Video Tutorials
            </h1>
            <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
              Learn how to make the most of VEEWER with our comprehensive video guides. From basic setup to advanced features, we've got you covered.
            </p>
          </div>

          {/* Timeline Style Video Cards */}
          <div className="relative max-w-5xl mx-auto">
            {/* Timeline Line */}
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-[#16254c] to-blue-600 rounded-full opacity-20"></div>

            {/* Cards Container */}
            <div className="space-y-20">
              {videos.map((video, index) => (
                <div key={video.url} className={`relative ${index % 2 === 0 ? 'lg:ml-auto lg:pl-24' : 'lg:mr-auto lg:pr-24'} lg:w-1/2`}>
                  {/* Timeline Dot */}
                  <div className={`hidden lg:block absolute top-1/2 transform -translate-y-1/2 w-5 h-5 rounded-full bg-gradient-to-r from-[#16254c] to-blue-600 shadow-lg ${index % 2 === 0 ? 'left-[6px]' : 'right-[6px]'}`}>
                    <div className="absolute inset-0 rounded-full bg-white scale-50"></div>
                  </div>

                  {/* Card */}
                  <div className="group relative bg-white rounded-2xl shadow-[0_8px_32px_rgba(22,37,76,0.08)] hover:shadow-[0_16px_48px_rgba(22,37,76,0.12)] transition-all duration-300">
                    {/* Gradient Border */}
                    <div className="absolute -inset-[1px] bg-gradient-to-br from-[#16254c] to-blue-600 rounded-2xl opacity-20 group-hover:opacity-30 transition-opacity"></div>

                    <div className="relative p-8">
                      {/* Icon */}
                      <div className="mb-6">
                        <div className="relative inline-block">
                          <div className="absolute -inset-2 bg-gradient-to-r from-[#16254c]/20 to-blue-500/20 rounded-full blur-lg group-hover:blur-xl transition-all duration-500"></div>
                          <div className="relative bg-gradient-to-br from-[#16254c] to-blue-600 p-4 rounded-2xl text-white transform group-hover:scale-110 transition-transform duration-300">
                            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                        </div>
                      </div>

                      {/* Content */}
                      <h3 className="text-2xl font-semibold text-gray-900 mb-4">{video.title}</h3>
                      <p className="text-gray-600 mb-8 leading-relaxed">{video.description}</p>

                      {/* Button */}
                      <button
                        onClick={() => setActiveVideo(video)}
                        className="group inline-flex items-center gap-2 text-[#16254c] font-medium hover:text-blue-600 transition-colors"
                      >
                        Watch Tutorial
                        <svg className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Video Modal */}
      <VideoModal
        video={activeVideo}
        onClose={() => setActiveVideo(null)}
      />
    </div>
  )
}